<template>
  <div class="container">
    <el-page-header class="mt-4" @back="$router.go(-1)" content="Preview"></el-page-header>
    <div class="text-right mt-2" v-if="isAdmin && question">
      <router-link
        :to="{
          name: 'SatEditQuestion',
          query: {
            questionId: question.id
          }
        }"
      >
        <el-button type="warning">
          <i class="fa fa-edit"></i>
          Edit
        </el-button>
      </router-link>
    </div>
    <div class="test-paper">
      <MultipleChoiceWithPassage
        v-if="
          question &&
            question.type === 'default' &&
            question.sat_passage_id > 0 &&
            question.sat_passage.content
        "
        mode="explanation"
        :passageIntro="
          question.sat_passage.introduction
            ? question.sat_passage.introduction.intro
            : null
        "
        :passageContent="getPassage"
        :questionOrder="questionOrder"
        :content="question.content"
        :options="question.sat_options"
        :answer="answer ? answer.answers[0] : '/'"
        :correctAnswer="question.sat_answers[0].answers[0]"
        :isCorrect="answer ? answer.is_correct == 1 : false"
        :explanation="question.explanation"
        :showMark="false"
        :showLine="question.sat_sat_subject.sat_subject.name === 'reading'"
        :activities="
          answer && answer.other_status ? answer.other_status.activities : null
        "
      ></MultipleChoiceWithPassage>
      <MultipleChoice
        v-else-if="
          question &&
          (question.type === 'ap' || question.type === 'ap_macroeconomic') &&
            (question.sat_passage === null ||
              question.sat_passage.content === null)
        "
        mode="explanation"
        :questionOrder="questionOrder"
        :content="question.content"
        :options="question.sat_options"
        :answer="answer ? answer.answers[0] : '/'"
        :correctAnswer="question.sat_answers.length > 0 ?question.sat_answers[0].answers[0]: null"
        :isCorrect="answer ? answer.is_correct == 1 : false"
        :explanation="question.explanation"
        :showMark="false"
        :activities="
          answer && answer.other_status ? answer.other_status.activities : null
        "
      ></MultipleChoice>
      <Math
        v-else-if="question && question.type === 'math'"
        mode="explanation"
        :questionOrder="questionOrder"
        :content="question.content"
        :options="question.sat_options"
        :answer="answer !== null ? answer.answers[0] : '/'"
        :correctAnswer="question.sat_answers[0].answers[0]"
        :isCorrect="answer ? answer.is_correct == 1 : false"
        :explanation="question.explanation"
        :showMark="false"
        :activities="
          answer && answer.other_status ? answer.other_status.activities : null
        "
      ></Math>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";


export default {
  metaInfo() {
    return {
      title: "Explanation - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      exam: null,
      practices: null,
      result: null,
      answer: null,
      question: null,
      activeName: null,
      questionOrder: "-"
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    questionId() {
      return this.$route.params.questionId;
    },
    getPassage() {
      let passage = "";
      let p1 = this.question.sat_passage.content;
      let p2 = this.question.sat_passage.introduction?.below_content;
      if (p2) {
        passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
      } else {
        passage = p1;
      }
      // passage = this.getLine(passage);
      return passage;
    }
  },
  watch: {},

  async mounted() {
    const res = await SAT.getExplanation(this.questionId);

    this.question = res.sat_question;
    if (!this.question.sat_sat_subject) {
      this.question.sat_sat_subject = {
        sat_subject: {
          id: 5,
          name: "english"
        }
      };
    }
    this.answer = res.user_exam_answer;
    // this.getUserAnswers();
  },

  methods: {
    // getLine(passage) {
    //   let new_passage = passage.replace(/[\n]/g, "<br>");
    //   new_passage = new_passage.replace(
    //     /<br>/g,
    //     "</p><p style='margin-bottom:0;height:34px;'>"
    //   );
    //   new_passage =
    //     "<p style='margin-bottom:0;height:34px;'>" + new_passage + "</p>";
    //   return new_passage;
    // },
    getUserAnswers() {
      let count = 0;
      this.practices.forEach(practice => {
        let arr = [];
        let correctCount = 0;

        practice.exam.exam_questions.forEach(question => {
          count++;
          let answer = null;
          this.result.user_exam_answers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question.id) {
              answer = userAnswer;
              if (userAnswer.is_correct === 1) {
                correctCount++;
              }
            }
          });
          question["userAnswer"] = answer;
          arr.push(answer);
        });
        practice["correctCount"] = correctCount;
      });
    }
  }
};
</script>

<style scoped>
.question-title {
  margin: 0 -1rem;
}
::v-deep .el-collapse-item .el-collapse-item__header {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover {
  background-color: var(--themeColor);
  color: white !important;
}
.view_question {
  position: relative;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1rem 5px 1rem;
  border: 0.1rem solid #d4edda;
  text-decoration: none;
  cursor: pointer;
  background-color: #d4edda;
  color: #212529 !important;
}
.wrong {
  border-color: #ffdadd;
  background-color: #ffdadd;
}
.view_question.empty {
  background-color: #ccc;
  border-color: #ccc;
}
.showTags {
  display: flex;
  /* justify-content: center; */
}
.showTags-item {
  margin: 0 10px 20px 0;
  display: flex;
}
.showTags-item .view_question {
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  margin: 0 5px;
}
.questionsOrderActive {
  position: absolute;
  display: inline-block;
  width: 1.8rem;
  left: 0;
  text-align: center;
  top: -1.7rem;
  color: var(--themeColor);
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>
